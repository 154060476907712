import { CCard, CCardBody, CCol, CCardHeader, CRow } from '@coreui/react'
import {
    CChartBar,
    CChartDoughnut,
    CChartLine,
    CChartPie,
    CChartPolarArea,
    CChartRadar,
} from '@coreui/react-chartjs'
import { DocsCallout } from 'src/components';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Flex, Input, Tabs, Upload, Select, Spin, Checkbox, Pagination } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { apiUrls } from '../../apiUrls.js';
import Program from "../../components/Program.js";
import { useNavigate } from 'react-router-dom';
import HideSidebarInMobileMode from "../../components/HideSidebarInMobileMode.js";
const baseURL = apiUrls.baseURL;

const Education = () => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState({ skip: 0, limit: 10 });
    const permissions = useSelector((state) => state.permissions.permissions);
    const accessShowInSite = permissions.includes('createblog');
    const [loadingReport, setLoadingReport] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        fetchData()
    }, [filter])

    const getReport = async () => {
        setLoadingReport(true)
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/WorkingGroupCategory/GetReport?id=11`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }, 'responseType': 'blob'
            });
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            window.open(url, '_blank');

            setLoadingReport(false)
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            setLoadingReport(false)
        } finally {
            setLoadingReport(false)
        }
    }
    const fetchData = async () => {

        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/program/geteducationnotifyprograms?skip=${filter.skip}&limit=${filter.limit}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });

            var result = response.data.data;
            setData(result);
            setLoading(false);
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <div style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 300
        }}><Spin size="large" /> <HideSidebarInMobileMode /></div>
    }

    return (
        <div>
            {permissions.includes('educationclassessreport') && <Button onClick={() => getReport()} loading={loadingReport}
                style={{ backgroundColor: 'rgb(28 27 229)', marginTop: 10, width: '100%', marginBottom: 10 }}
                type="primary"><label>گزارش</label></Button>}
            <div className='row'>
                {data?.items?.map((item, index) => {
                    return <Program
                        key={index}
                        programKey='education'
                        color='rgb(210 255 253)'
                        isShowPolicy={true}
                        item={item}
                        isShowAddMember={true}
                        isShowCreateDate ={true}
                        onRefresh={fetchData}
                        isShowAssistantLeaderAmount={true}
                        isShowOtherProgramCosts={true}>
                    </Program>
                })}
                <Pagination
                    onChange={(pageNumber, limit) => setFilter({ skip: pageNumber - 1, limit: limit })}
                    style={{ marginTop: 30, marginBottom: 5 }}
                    current={filter.skip + 1}
                    responsive={true}
                    pageSize={filter.limit}
                    showTotal={(total) => `تعداد کل: ${total} کلاس`}
                    total={data.count} />
            </div>
        </div>
    )
}

export default Education
