import { CCard, CCardBody, CCol, CCardHeader, CRow } from '@coreui/react'
import {
    CChartBar,
    CChartDoughnut,
    CChartLine,
    CChartPie,
    CChartPolarArea,
    CChartRadar,
} from '@coreui/react-chartjs'
import { DocsCallout } from 'src/components';
import { useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Flex, Input, Tabs, Upload, Select, Spin, Checkbox, Pagination, Table, Collapse, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { apiUrls } from '../../apiUrls.js';
import Program from "../../components/Program.js";
import { useNavigate } from 'react-router-dom';
import { UnlockOutlined, LockOutlined, EditOutlined, SearchOutlined, DeleteOutlined } from '@ant-design/icons';
import AmountFormat from "../../components/AmountFormat.js";
import InputNumberFormat from "../../components/InputNumberFormat.js";
import DatePicker, { utils } from '@hassanmojab/react-modern-calendar-datepicker';
import { UploadOutlined } from '@ant-design/icons';
import jquery from 'jquery';

const { Option } = Select;

const baseURL = apiUrls.baseURL;

const CallbackPayment = () => {

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    useEffect(() => {
        setLoading(true);
        checkout()
    }, [])

    const checkout = async () => {
        let authority = searchParams.get('Authority');
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/payment/checkout?authority=${authority}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            var result = response.data.data;
            setData({ status: result.status, message: result.message });
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <div style={{ padding: '1px', textAlign: 'center', height: 200, alignItems: 'center', display: 'flex', justifyContent: 'center', backgroundColor: 'white', fontSize: '20px' }}>لطفا تا زمان مشخص شدن نتیجه پرداخت منتظر بمانید...</div>
    }
    return (
        <div>
            {data.status == 4 && <div style={{ padding: '1px', textAlign: 'center', height: 200, alignItems: 'center', display: 'flex', justifyContent: 'center', backgroundColor: '#ff9c9c', fontSize: '20px' }}>{data.message}</div>}
            {data.status == 3 && <div style={{ padding: '1px', textAlign: 'center', height: 200, alignItems: 'center', display: 'flex', justifyContent: 'center', backgroundColor: 'rgb(185 255 156)', fontSize: '20px' }}>{data.message}</div>}
            {data.status == 5 && <div style={{ padding: '1px', textAlign: 'center', height: 200, alignItems: 'center', display: 'flex', justifyContent: 'center', backgroundColor: 'rgb(255 211 156)', fontSize: '20px' }}>{data.message}</div>}
            {data.status == 7 && <div style={{ padding: '1px', textAlign: 'center', height: 200, alignItems: 'center', display: 'flex', justifyContent: 'center', backgroundColor: 'rgb(255 211 156)', fontSize: '20px' }}>{data.message}</div>}
            <Button size='small' onClick={() => navigate('/financial/mywallet')} style={{ backgroundColor: 'rgb(4 49 151)', marginTop: 10, width: '100%' }}
                type="primary">بازگشت</Button>
        </div>
    )
}

export default CallbackPayment
