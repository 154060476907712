import { CCard, CCardBody, CCol, CCardHeader, CRow } from '@coreui/react'
import {
    CChartBar,
    CChartDoughnut,
    CChartLine,
    CChartPie,
    CChartPolarArea,
    CChartRadar,
} from '@coreui/react-chartjs'
import { DocsCallout } from 'src/components'
import { toast } from 'react-toastify';
import { Button, Flex, Input, Upload, Select, Spin, Checkbox } from 'antd';
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
import DatePicker, { utils } from '@hassanmojab/react-modern-calendar-datepicker';
import jquery from 'jquery';
import { useParams } from 'react-router-dom';
import { apiUrls } from '../../apiUrls.js';
import { useNavigate } from 'react-router-dom';
import MemberSpecification from "../../components/MemberSpecification.js";
import MemberCertificate from "../../components/MemberCertificate.js";
import MemberJoinedProgram from "../../components/MemberJoinedProgram.js";
import MemberLeaderProgram from "../../components/MemberLeaderProgram.js";
import HideSidebarInMobileMode from "../../components/HideSidebarInMobileMode.js";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
// const { TabPane } = Tabs;
const { Option } = Select;

const Detail = () => {

    const [data, setData] = useState({});
    const [roleData, setRoleData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [dropDownRoles, setDropDownRoles] = useState([]);
    const [dropDownType, setDropDownTypes] = useState([]);
    const [startMemberingDate, setStartMemberingDate] = useState(null);
    const baseURL = apiUrls.baseURL;
    const navigate = useNavigate();
    const { id } = useParams();
    const permissions = useSelector((state) => state.permissions.permissions);
    const [renderedTabs, setRenderedTabs] = useState([0]);
    const hasChangeRolePermission = permissions.includes('createrole');
    const hasChangeStartDateMemberingPermission = permissions.includes('createmember');

    useEffect(() => {
        fetchData()
        fetchMemberTypes()
    }, [])

    useEffect(() => {
        if (hasChangeRolePermission) {
            fetchRoles()
            fetchUserRoles()
        }
    }, [permissions])

    function callback(key) {

    }

    const onSelect = useCallback((index) => {
        setRenderedTabs((renderedTabs) =>
            renderedTabs.includes(index)
                ? renderedTabs
                : renderedTabs.concat(index)
        )
    }, []);

    const setSelectedStartMemberingDate = async (date) => {
        setStartMemberingDate({
            year: date.year,
            month: date.month,
            day: date.day
        });
        setData({ ...data, startMemberingDate: `${date.year}/${date.month}/${date.day}` })
    }

    const save = async () => {
        setLoadingSave(true);
        try {
            const token = localStorage.getItem('damavandclub-token')
            await axios.post(`${baseURL}/user/updateByOrganizationAdminGroup`, data, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });

            if (hasChangeRolePermission) {
                await axios.post(`${baseURL}/user/updateroles?id=${id}`, roleData, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                });
            }
            toast.success("تغییرات با موفقیت ذخیره شد", {
                position: 'bottom-right',
                autoClose: 3000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setLoadingSave(false);
        } catch (error) {
            toast.error(error.response.data.Msg, {
                position: 'bottom-right',
                autoClose: 6000, // milliseconds
                hideProgressBar: false,
                closeOnClick: true,
                //className: 'custom-toast',
                pauseOnHover: true,
                draggable: true,
            });
            setLoadingSave(false);
        } finally {
            setLoadingSave(false);
        }
    }

    const fetchRoles = async () => {
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/role/getDropdownItems`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            })

            var result = response.data.data;
            setDropDownRoles(result);
        } catch (error) {
        } finally {
        }
    };

    const fetchUserRoles = async () => {
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/user/getroles?id=${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            })
            var result = response.data.data;
            setRoleData(result.map(x => x.id))
        } catch (error) {
        } finally {
        }
    };

    const fetchMemberTypes = async () => {
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/user/getTypeDropdownItems`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            })
            var result = response.data.data;
            setDropDownTypes(result)
        } catch (error) {
        } finally {
        }
    };

    const fetchData = async () => {
        setLoading(true);
        const token = localStorage.getItem('damavandclub-token')
        try {
            const response = await axios.get(`${baseURL}/user/getUserDetail?id=${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });

            var result = response.data.data;
            result.id = id;
            var resultStartMemberingDate = result.startMemberingDate.split('/');
            setStartMemberingDate({
                year: parseInt(resultStartMemberingDate[0], 10),
                month: parseInt(resultStartMemberingDate[1], 10),
                day: parseInt(resultStartMemberingDate[2], 10)
            })
            setData(result);
            setLoading(false);
        } catch (error) {
            if (error.response.status == 401)
                navigate('/');
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Spin size="large" />
    }

    return (
        <div className='row'>
            <HideSidebarInMobileMode />
            <div style={{
                backgroundColor: 'white',
                flexDirection: 'column',
                display: 'flex',
                flex: 1,
                height: '100%',
                padding: 10
            }}>
                <Button onClick={() => save()} loading={loadingSave} style={{ backgroundColor: 'rgb(0 151 46)', marginTop: 10, width: '100%', marginBottom: 10 }}
                    type="primary"><label>ذخیره تمام تغییرات پروفایل</label></Button>
                <Tabs onSelect={onSelect}>
                    <TabList>
                        <Tab>مشخصات</Tab>
                        <Tab>مدارک</Tab>
                        <Tab>حضور در برنامه</Tab>
                        <Tab>سرپرستی ها</Tab>
                    </TabList>

                    <TabPanel forceRender={renderedTabs.includes(0)}>

                        <label>نام شناسنامه ای</label>
                        <Input value={data.originalFirstName} onChange={(event) => setData({ ...data, originalFirstName: event.target.value })} style={{ marginTop: 5 }} size="large" />
                        <label className='margintop-7'>نام خانوادگی شناسنامه ای</label>
                        <Input value={data.originalLastName} onChange={(event) => setData({ ...data, originalLastName: event.target.value })} style={{ marginTop: 5 }} size="large" />

                        <MemberSpecification data={data} setData={setData}></MemberSpecification>

                        <label className='margintop-7'>تاریخ عضویت</label>
                        {hasChangeStartDateMemberingPermission ? <div className='margintop-7 center-content customDatePickerWidth'>
                            <DatePicker
                                value={startMemberingDate || null}
                                //inputClassName='fullwidth'
                                onChange={(value) => setSelectedStartMemberingDate(value)}
                                //minimumDate={persianToday}
                                //calendarClassName="fullwidth"
                                shouldHighlightWeekends
                                locale='fa'
                            />
                        </div> : <div style={{ border: '1px solid lightgray' }} className='margintop-7 center-content customDatePickerWidth'>
                            <label>{data.startMemberingDate}</label>
                        </div>}

                        <label className='margintop-7'>شماره عضویت</label>
                        <Input value={data.memberNumber} onChange={(event) => setData({ ...data, memberNumber: event.target.value })} style={{ marginTop: 5 }} size="large" />

                        <label className='margintop-7'>تاریخ اعتبار بیمه ورزشی</label>
                        <div style={{ border: '1px solid lightgray' }} className='margintop-7 center-content customDatePickerWidth'>
                            <label>{data.insuranceExpiredDate}</label>
                        </div>

                        <label className='margintop-7'>انتخاب نوع عضویت</label> <Select
                            style={{ width: '100%' }}
                            placeholder="انتخاب نوع عضویت"
                            onChange={(value) => setData({ ...data, type: value })}
                            value={data.type}
                        >
                            {dropDownType?.map((item) => (
                                <Option key={item.id} value={item.id}>
                                    {item.displayName}
                                </Option>
                            ))}
                        </Select>

                        {hasChangeRolePermission ? <> <label className='margintop-7'>انتخاب نقش سیستمی</label> <Select
                            style={{ width: '100%' }}
                            placeholder="انتخاب نقش"
                            mode="multiple"
                            onChange={(value) => setRoleData(value)}
                            value={roleData}
                        >
                            {dropDownRoles?.map((item) => (
                                <Option key={item.id} value={item.id}>
                                    {item.displayName}
                                </Option>
                            ))}
                        </Select></> : null}

                        <div><Checkbox checked={data.isActive} onChange={() => setData({ ...data, isActive: !data.isActive })}>اکانت فعال/غیرفعال</Checkbox></div>

                        <div><Checkbox checked={data.isDeaded} onChange={() => setData({ ...data, isDeaded: !data.isDeaded })}>مرحوم</Checkbox></div>

                        <div>
                            <a rel="noreferrer" target="_blank" href={`https://wa.me/98${data.phoneNumber}`} >تماس از طریق واتساپ</a>
                        </div>

                    </TabPanel>
                    <TabPanel forceRender={renderedTabs.includes(1)}>
                        <MemberCertificate isShowButton={true} data={data} setData={setData}></MemberCertificate>
                    </TabPanel>
                    <TabPanel forceRender={renderedTabs.includes(2)}>
                        <MemberJoinedProgram data={data.donedPrograms}></MemberJoinedProgram>
                    </TabPanel>
                    <TabPanel forceRender={renderedTabs.includes(3)}>
                        <MemberLeaderProgram data={data.leaderPrograms}></MemberLeaderProgram>
                    </TabPanel>
                </Tabs>
            </div>
        </div >
    )
}

export default Detail
